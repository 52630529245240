import { get } from 'lodash'

const defaultLogo = require('../../assets/custom-default/default-logo.svg')
const defaultHero = require('../../assets/custom-default/default-hero.svg')

export type TemplateId = 'template-1' | 'template-2' | 'template-3' | 'template-4'

type Field = Partial<Record<'default' | 'placeholder' | TemplateId, any>>

// All fields
const logo: Field = {
  default: { name: 'default-logo.png', data: defaultLogo }
}

const alreadyMember: Field = {
  default: true
}

const phone: Field = {}

const headline: Field = {
  'template-1': {
    placeholder: 'Searching for an affordable, easy-to-use health plan? You found it.'
  },
  'template-2': {
    placeholder: 'Searching for an affordable, easy-to-use health plan? You found it.'
  },
  'template-3': {
    placeholder: 'Let\'s get started. Find a doctor or facility in your plan\'s network.'
  },
  'template-4': {
    placeholder: 'Let\'s get started. Find a doctor or facility in your plan\'s network.'
  }
}

const description: Field = {
  'template-4': {
    placeholder: 'The Centivo Network is built around trusted, quality providers proven to deliver top results for their patients. The Centivo Network includes all the doctors and specialists you may need, including virtual options. Please contact us if you need help finding a certain type of provider near you.'
  }
}

const hero: Field = {
  default: { name: 'default-hero.png', data: defaultHero }
}

const withCentivo: Field = {
  placeholder: [
    'Affordable, predictable costs, so you know what you owe, if anything, before your visits.',
    'A primary care doctor to coordinate all your healthcare needs and refer you to trusted, in-network specialists and facilities.',
    'Dedicated hands-on member support by phone or through the Centivo app.',
    'A simple, straightforward plan with an easy-to-use app and member portal.'
  ],
  default: []
}

const vpc: Field = {
  default: true
}

const centivoCare: Field = {
  default: {
    title: 'FREE virtual care when you need it, where you want it',
    body: 'Centivo Care is a convenient alternative to in-person primary care — and all visits are entirely FREE! With Centivo Care, you have access to a primary care team with integrated behavioral health, plus access to a clinician 24/7/365 for urgent needs. With our simple, engaging app, you can visit with your doctor from the comfort of your home, at work or on the go — from your phone or computer. If at any point you need specialist attention or to be seen in person, your Centivo Care team will guide you to appropriate in-network virtual or community providers.',
    text: 'Learn more',
    link: 'https://www.centivocare.com/'
  }
}

const networkOfExcellence: Field = {
  placeholder: [
    'The Centivo Network is built around trusted, quality providers proven to deliver top results for their patients.\n\n The Centivo Network includes all the doctors and specialists you may need, including virtual options.'
  ],
  default: []
}

const disclaimer: Field = {
  default: true
}

const testimonials: Field = {
  default: false,
  'template-1': {
    default: true
  }
}

const heroButton = {
  default: {
    enabled: false,
    text: '',
    link: ''
  }
}

const externalLink = {
  default: {
    enabled: false,
    text: '',
    link: ''
  }
}

const video = {}
// ----

export const templateFields: Record<TemplateId, Record<string, any>> = {
  'template-1': {
    logo,
    alreadyMember,
    phone,
    headline: headline['template-1'],
    hero,
    video,
    withCentivo,
    vpc,
    centivoCare,
    networkOfExcellence,
    disclaimer,
    testimonials,
    heroButton
  },
  'template-2': {
    alreadyMember,
    testimonials: {
      ...testimonials,
      default: false
    },
    networkOfExcellence,
    phone,
    logo,
    hero,
    video,
    headline: headline['template-2'],
    vpc,
    centivoCare,
    disclaimer
  },
  'template-3': {
    logo,
    alreadyMember,
    phone,
    headline: headline['template-3'],
    hero,
    video,
    vpc,
    centivoCare,
    disclaimer
  },
  'template-4': {
    logo,
    alreadyMember,
    phone,
    headline: headline['template-4'],
    externalLink,
    description: description['template-4']
  }
}

export const getTemplateDefault = (template: TemplateId = 'template-1') => {
  const fields = templateFields[template]

  return Object.keys(fields).reduce((acc, curr) => {
    const currField = fields[curr]
    const defaultValue = get(fields, template in currField ? [curr, template, 'default'] : [curr, 'default'])

    if (defaultValue === undefined || curr === 'hero' || curr === 'logo') return acc

    return {
      ...acc,
      [curr]: defaultValue
    }
  }, {
    withCentivo: [],
    networkOfExcellence: []
  })
}
export const defaultValues = Object.fromEntries(Object.keys(templateFields).map((templateId) => ([templateId, getTemplateDefault(templateId as TemplateId)])))

export const templateInfo: {
  key: TemplateId,
  title: string,
  default?: boolean
}[] = [
  {
    key: 'template-1',
    title: 'Template 1 - Standard',
    default: true
  },
  {
    key: 'template-2',
    title: 'Template 2 - Tailored'
  },
  {
    key: 'template-3',
    title: 'Template 3 - Basic'
  },
  {
    key: 'template-4',
    title: 'Template 4 - Slice Rebrand'
  }
]
