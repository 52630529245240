
import { defineComponent } from 'vue'
import { PropType } from 'vue/types/v3-component-props'

import SectionContainer from './section.vue'

export default defineComponent({
  name: 'noe-section',
  props: {
    networkOfExcellence: {
      type: Array as PropType<string[]>,
      required: false
    }
  },
  data: function () {
    return {
      defaultNoe: ['The Centivo Network is built around trusted, quality providers proven to deliver top results for their patients.', 'The Centivo Network includes all the doctors and specialists you may need, including virtual options.']
    }
  },
  components: {
    SectionContainer
  }
})
