
import { defineComponent } from 'vue'
import Cleave from 'cleave.js'

import CustomSelect from '../custom-select.vue'
import TextToggle from '../text-toggle.vue'
import ImageInput from '../../components/form/ImageInput.vue'
import Accordion from '../../components/form/Accordion.vue'
import { templateInfo, templateFields, TemplateId, getTemplateDefault } from './data'
import { obsIsEmpty, obsIsEqual } from '@/helpers'
import { cloneDeep } from 'lodash'

const cleave = {
  name: 'cleave',
  bind (el: any, binding: any) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind (el: any) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

const defaultTemplate = templateInfo.find(e => e.default) ?? templateInfo[0]
const defaultTemplateFields = templateFields[defaultTemplate.key]
const isCentivoCareEnabled = process.env.VUE_APP_CENTIVO_CARE_ENABLED === 'true'

export interface CustomizationFormErrors {
  phone: boolean
  any: boolean
}

export default defineComponent({
  name: 'customization-form',

  directives: { cleave },

  components: {
    CustomSelect,
    ImageInput,
    TextToggle,
    Accordion
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    clientConfig: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      data: { ...this.value, withCentivo: [], networkOfExcellence: [] } as Record<string, any>,
      errors: {
        any: false,
        phone: false,
        video: false,
        button: false,
        buttonLabel: false,
        buttonUrl: false,
        externalLink: false,
        externalLinkLabel: false,
        externalLinkUrl: false
      },
      vpcNetworkCodes: [],

      // static values
      isCentivoCareEnabled,
      defaultCentivoCareData: { ...defaultTemplateFields?.centivoCare.default },
      templateSelectOptions: templateInfo.map(e => e.title),
      defaultLogoImage: defaultTemplateFields?.logo?.default,
      defaultHeroImage: defaultTemplateFields?.hero?.default,
      headlinePlaceholder: defaultTemplateFields.headline?.placeholder,
      descriptionPlaceholder: templateFields['template-4'].description?.placeholder,
      withCentivoPlaceholder: defaultTemplateFields.withCentivo?.placeholder,
      networkOfExcellence: defaultTemplateFields.networkOfExcellence?.placeholder,
      masks: {
        phone: {
          delimiters: ['-', '-', '-'],
          blocks: [3, 3, 4],
          numericOnly: true
        }
      }
    }
  },

  mounted () {
    const { selectedTemplate, ...rest } = this.value
    if (obsIsEmpty(rest) || Object.keys(rest).every(key => obsIsEqual(templateFields[defaultTemplate.key][key]?.default, rest[key]))) {
      this.loadDefaultValues(selectedTemplate)
    }
  },

  watch: {
    data: {
      handler: function (newVal, oldVal) {
        if (newVal.phone?.length > 12) {
          newVal.phone = newVal.phone.slice(0, 12)
          this.data = newVal
        }

        this.$emit('input', newVal)
      },
      deep: true
    },
    errors: {
      handler: function (newVal, oldVal) {
        const { any, ...fieldErrors } = newVal
        const { any: oldAny, ...oldFieldErrors } = oldVal

        if (
          oldFieldErrors &&
          Object.values(fieldErrors).every(
            (val, index) => val === Object.values(oldFieldErrors)[index]
          )
        ) return

        const newErrors = {
          ...newVal,
          any: Object.values(fieldErrors).some(e => !!e)
        }
        this.errors = newErrors
        this.$emit('errors', newErrors)
      },
      deep: true
    }
  },

  computed: {
    enabledFields (): Record<string, unknown> {
      return templateFields[this.data.selectedTemplate ?? defaultTemplate.key]
    },

    vpcDisabled (): boolean {
      if (!this.clientConfig?.networkConfigs) return true
      return !this.clientConfig.hasPcpNetwork
    },

    selectedTemplateTitle (): string {
      return templateInfo.find(t => t.key === this.value?.selectedTemplate)?.title ?? defaultTemplate.title
    },

    filteredTemplateSelectOptions (): string[] {
      console.log(process.env.VUE_APP_SUPPRESS_TEMPLATE_4 === 'true')

      let templateSelectOptions = this.templateSelectOptions

      if (process.env.VUE_APP_SUPPRESS_TEMPLATE_4 === 'true') {
        templateSelectOptions = templateSelectOptions.filter(e => e !== 'Template 4 - Slice Rebrand')
      }

      return templateSelectOptions
    }
  },

  methods: {
    validate () {
      this.errors.phone = !!this.data.phoneEnabled && (this.data.phone.length !== 12 || this.data.phone === '000-000-0000')
      this.errors.video = !!this.enabledFields.video && (this.data.videoEnabled ?? false) && (!this.data.video || !this.data.video.match(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/
      ))
      this.errors.buttonLabel = !!this.data.heroButtonEnabled && !this.data.heroButtonText
      this.errors.buttonUrl = !!this.data.heroButtonEnabled && !this.data.heroButtonUrl
      this.errors.button = this.errors.buttonLabel && this.errors.buttonUrl
      this.errors.externalLinkLabel = !!this.data.externalLinkEnabled && !this.data.externalLinkLabel
      this.errors.externalLinkUrl = !!this.data.externalLinkEnabled && !this.data.externalLinkUrl
      this.errors.externalLink = this.errors.externalLinkLabel && this.errors.externalLinkUrl

      return this.errors
    },

    isValid () {
      this.validate()

      return Object.values(this.errors).every(bool => bool === false)
    },

    onTemplateChange (templateTitle: string) {
      const selectedTemplate = templateInfo.find(i => i.title === templateTitle)?.key || defaultTemplate.key

      this.$nextTick(() => {
        this.loadDefaultValues(selectedTemplate)
      })
    },

    loadDefaultValues (selectedTemplate: TemplateId = defaultTemplate.key) {
      const enabledFields = templateFields[selectedTemplate]

      if (enabledFields) {
        const prevData = cloneDeep(this.data)
        const prevDefault = getTemplateDefault(prevData.selectedTemplate)

        Object.keys(prevData).forEach(f => {
          const fieldValue = prevData[f]

          if (fieldValue === null || fieldValue === prevDefault[f] || !Object.keys(enabledFields).includes(f)) {
            delete prevData[f]
          }
        })

        this.data = {
          ...getTemplateDefault(selectedTemplate),
          ...prevData,
          selectedTemplate
        }
      }
    },

    setImageURI (dataUrl: string | null, type: 'logo' | 'hero') {
      this.data[type] = dataUrl

      // NOTE: this mimics what the watcher for "data" would do.
      // This is necessary because Vue for some reason doesn't recognize
      // this variable changing. My guess, it's because the string is too big
      this.$emit('input', this.data)
    },

    clearErrors (keys?: string[]) {
      Object.keys(this.errors).forEach(key => {
        if (!keys || keys.includes(key)) {
          this.errors[key] = false
        }
      })
    }
  }
})
