
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'section-container',
  props: {
    fluid: {
      type: Boolean,
      required: false
    }
  }
})
