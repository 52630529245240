
import { defineComponent } from 'vue'
import SectionContainer from './section.vue'

export default defineComponent({
  name: 'vpc-section',
  components: {
    SectionContainer
  }
})
