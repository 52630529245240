
import { defineComponent } from 'vue'
import SectionContainer from './section.vue'

export default defineComponent({
  name: 'testimonials-section',
  components: {
    SectionContainer
  },
  data () {
    return {
      testimonials: [
        'There were no unexpected costs and we knew everything up front, so there was no financial burden on us.',
        'I feel like I can take care of myself and my family without being in debt. Thank you!',
        'I feel like my health plan is my partner and not my enemy. In the past, I used to have to fight with them all the time.'
      ]
    }
  }
})
