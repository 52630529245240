<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" viewBox="0 0 18 25" fill="none">
    <path
      d="M17 15.7039C13.9084 15.4902 9.05013 17.0502 9.05013 25L9.05013 1.36211e-06M0.999998 15.7039C4.09161 15.4902 8.94986 17.0502 8.94986 25"
      :stroke="color" stroke-width="1.08868" />
  </svg>
</template>
<script>
export default {
  name: 'SliceDownArrowIcon',
  props: {
    color: String
  }
}
</script>
