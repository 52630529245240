
import { defineComponent } from 'vue'
import { PropType } from 'vue/types/v3-component-props'

import SectionContainer from './section.vue'
import { TemplateId } from '../customization-form/data'

export default defineComponent({
  name: 'provider-search',
  components: {
    SectionContainer
  },
  props: {
    disclaimer: {
      type: Boolean,
      required: false
    },
    selectedTemplate: {
      type: String as PropType<TemplateId>,
      required: false
    }
  },
  data: function () {
    return {
      activeTab: 0,
      specialtyOptions: ['Primary Care', 'Acupuncture', 'Allergy and Immunology', 'Anesthesiology', 'Audiology', 'Behavioral Health', 'Cardiology', 'Chiropractic Care', 'Colorectal Surgery', 'Dentistry', 'Dermatology', 'Dialysis/Rehabilitation', 'Emergency', 'Endocrinology', 'Gastroenterology and Hepatology', 'General Surgery', 'Genetics', 'Home Health', 'Hospital', 'Hospital', 'Hyperbaric Medicine', 'Imaging/Radiology', 'Imaging/Radiology', 'Infectious Disease', 'Internal Medicine', 'Labs', 'Medical Supplies', 'Nephrology', 'Neurology', 'Nurse Practitioners', 'Nutrition', 'Oncology and Hematology (Cancer and Blood)', 'Orthopedics', 'Other', 'Otolaryngology', 'Pediatrics', 'Pharmacy', 'Physical Medicine/Therapy', 'Physical Medicine/Therapy', 'Plastic and Reconstructive Surgery', 'Podiatry', 'Primary Care', 'Pulmonology', 'Rheumatology', 'Skilled Nursing Facility', 'Speech Therapy', 'Surgery Centers', 'Transplant', 'Urgent Care', 'Urology', 'Vascular Surgery', 'Vision', "Women's Health"],
      zipCode: '',
      providerName: ''
    }
  },
  methods: {
    onClickField (e: Event) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
})
