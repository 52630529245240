
import { PropType } from 'vue/types/v3-component-props'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'alert',
  props: {
    title: {
      type: String,
      required: false
    },
    type: {
      type: String as PropType<'sucess' | 'warning' | 'error' | 'info' >,
      validator (value: string) {
        return ['success', 'warning', 'error', 'info'].includes(value)
      },
      default () {
        return 'success'
      }
    }
  }
})
