
import { defineComponent } from 'vue'
import { PropType } from 'vue/types/v3-component-props'
import { TemplateId } from '../customization-form/data'

import defaultLogo from '../../assets/custom-default/default-logo.svg'

export default defineComponent({
  name: 'preview-navbar',
  props: {
    logo: {
      type: Object as PropType<{ data: string; name: string }>,
      required: false
    },
    alreadyMember: {
      type: Boolean,
      required: false,
      default: true
    },
    phoneEnabled: {
      type: Boolean,
      required: false
    },
    phone: {
      type: String,
      required: false
    },
    selectedTemplate: {
      type: String as PropType<TemplateId>,
      required: false
    }
  },
  computed: {
    phoneText (): string | undefined {
      return this.phone
        ? this.phone.split('-').reduce<string>((acc, curr, i) => {
          if (i === 0) {
            return `(${curr})`
          }

          return i === 1 ? `${acc} ${curr}` : `${acc}-${curr}`
        }, '')
        : this.phone
    },
    logoSrc (): string {
      return this.logo?.data ?? defaultLogo
    }
  }
})
