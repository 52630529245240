
import { defineComponent, PropType } from 'vue'

import { checkAllowedFileExtensions, getDataURIFromFile, obsIsEqual } from '../../helpers'

export default defineComponent({
  name: 'ImageInput',
  props: {
    allowedImageSize: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    value: {
      type: [Object, null] as PropType<Record<string, unknown> | null>,
      required: false
    },
    default: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      file: null as File | null,
      fileName: this.value?.name ?? this.default?.name,
      fileURI: this.value?.data ?? this.default?.data,
      allowedTypes: ['PNG', 'JPG', 'JPEG', 'GIF', 'SVG'],
      uploadError: false
    }
  },
  watch: {
    value: {
      handler: function (newVal?: { name: string, data: string } | null) {
        if (!obsIsEqual(this.fileURI, newVal ?? {})) {
          this.fileName = newVal?.name
          this.fileURI = newVal?.data
        }
      },
      deep: true
    }
  },
  methods: {
    formatFileName (fileName: string) {
      let formattedName = fileName

      if (String.prototype.includes.call(fileName, 's3.amazonaws.com')) {
        formattedName = fileName
          .substr(fileName.lastIndexOf('/'), fileName.length)
          .replace('/', '')
      }

      if (fileName === this.default?.name) {
        formattedName = fileName.includes('hero')
          ? 'default-hero.svg'
          : fileName.includes('logo')
            ? 'default-logo.svg'
            : fileName
      }

      return formattedName
    },

    onDeleteImage () {
      this.fileName = this.default?.name
      this.fileURI = this.default?.data
      this.file = null

      return this.$emit('onChangeValue', null)
    },

    onErrorUploadImage (e?: unknown) {
      this.uploadError = true

      this.$emit('onError', e)
    },

    async onUploadImage (imageInput: File) {
      const file = Array.isArray(imageInput)
        ? (imageInput.length > 0 ? imageInput[0] : null)
        : imageInput

      if (!checkAllowedFileExtensions(file, this.allowedTypes)) {
        return this.onErrorUploadImage()
      }

      this.file = imageInput
      this.$emit('onInput', imageInput)

      const dataURL = await getDataURIFromFile(file)
      if (!dataURL) {
        return this.onErrorUploadImage()
      }

      this.uploadError = false
      this.fileName = imageInput.name
      this.fileURI = dataURL

      return this.$emit('onChangeValue', { name: this.fileName, data: dataURL })
    },
    async getDataURI () {
      return getDataURIFromFile(this.file)
    }
  }
})
