import { render, staticRenderFns } from "./centivo-care-section.vue?vue&type=template&id=7ebcfaf4&scoped=true"
import script from "./centivo-care-section.vue?vue&type=script&lang=ts"
export * from "./centivo-care-section.vue?vue&type=script&lang=ts"
import style0 from "./centivo-care-section.vue?vue&type=style&index=0&id=7ebcfaf4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ebcfaf4",
  null
  
)

export default component.exports