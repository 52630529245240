
import { defineComponent } from 'vue'
import SectionContainer from './section.vue'
import { PropType } from 'vue/types/v3-component-props'
import { templateInfo, templateFields } from '../customization-form/data'

interface CentivoCareType {
  title: string
  body: string
  link: string
  text: string
};

const defaultTemplate = templateInfo.find(e => e.default) ?? templateInfo[0]
const defaultTemplateFields = templateFields[defaultTemplate.key]

export default defineComponent({
  name: 'centivo-care-section',
  props: {
    centivoCare: {
      type: Object as PropType<CentivoCareType>,
      required: true
    }
  },
  components: {
    SectionContainer
  },
  data: function () {
    return {
      cardData: this.centivoCare,
      defaultData: defaultTemplateFields.centivoCare.default
    }
  },
  methods: {
    pickNotEmpty (a: string, b: string) {
      return a !== '' ? a : b
    }
  },
  watch: {
    centivoCare: {
      handler: function (newVal: CentivoCareType) {
        this.cardData = newVal
      },
      deep: true
    }
  }
})
