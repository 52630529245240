
import { PropType } from 'vue/types/v3-component-props'
import { defineComponent } from 'vue'

import dashedBg from '../../../assets/oe-preview/dashed-line.svg'
import { TemplateId, templateFields } from '../../customization-form/data'
import SectionContainer from '../section.vue'

export default defineComponent({
  name: 'preview-hero',
  props: {
    headline: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    externalLinkEnabled: {
      type: Boolean,
      required: false
    },
    externalLinkLabel: {
      type: String,
      required: false
    },
    externalLinkUrl: {
      type: String,
      required: false
    },
    selectedTemplate: {
      type: String as PropType<TemplateId>,
      required: false,
      default: 'template-1'
    }
  },
  components: {
    SectionContainer
  },
  computed: {
    cssVars (): { '--bg-img': string } {
      return {
        '--bg-img': `url(${dashedBg})`
      }
    },
    headlineText (): string {
      const defaultHeadline = templateFields[this.selectedTemplate].headline

      return (!this.headline || this.headline === '') ? defaultHeadline.placeholder : this.headline
    },
    descriptionText (): string {
      const defaultDescription = templateFields[this.selectedTemplate].description

      return (!this.description || this.description === '') ? defaultDescription.placeholder : this.description
    }
  }
})
