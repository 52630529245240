
import { defineComponent } from 'vue'
import { PropType } from 'vue/types/v3-component-props'

import WcygMoney from '@/assets/oe-preview/wcyg-money.svg'
import WcygDoctor from '@/assets/oe-preview/wcyg-doctor.svg'
import WcygConcierge from '@/assets/oe-preview/wcyg-concierge.svg'
import WcygExperience from '@/assets/oe-preview/wcyg-experience.svg'

import SectionContainer from './section.vue'

const defaultCards = [
  'Affordable, predictable costs, so you know what you owe, if anything, before your visits.',
  'A primary care doctor to coordinate all your healthcare needs and guide you to trusted, in-network specialists and facilities.',
  'Dedicated hands-on member support by phone or through the Centivo app.',
  'A simple, straightforward plan with an easy-to-use app and member portal.'
]

export default defineComponent({
  name: 'wcyg-section',
  props: {
    withCentivo: {
      type: Array as PropType<string[]>,
      required: false
    }
  },
  data: function () {
    return {
      cardText: defaultCards,
      cardImgs: [
        WcygMoney,
        WcygDoctor,
        WcygConcierge,
        WcygExperience
      ]
    }
  },
  components: {
    SectionContainer
  },
  watch: {
    withCentivo: {
      handler: function (newVal: string[]) {
        const newText = defaultCards.map((text, i) => {
          if (newVal[i] && newVal[i] !== '') {
            return newVal[i]
          }

          return text
        })

        this.cardText = newText
      },
      deep: true
    }
  }
})
