
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'text-toggle',

  props: {
    text: {
      type: String,
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    contentEnabled: {
      type: Boolean,
      default: false
    },
    contentSlotMaxWidth: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasError (): boolean {
      return this.errorMessage !== undefined
    }
  }
})
